import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Switch, Spin } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import awsLogo from '../../../assets/images/aws.svg';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { customFilterOption, customSortOptions, formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { fetchBranches } from '../../../apis/Integrations';
import refreshIcon from '@iso/assets/images/refresh.svg';


const Option = SelectOption;

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

let inputStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "44px"
}

const widgetStyle = {
    margin: "20px",
    width: "100%",
};

let imgStyle = {
    width: '16px',
    height: '20px',
    marginRight: '10px'
}

const boxStyle = {
    width: "100%",
    marginTop: "0",
    border: "0",
    padding: "0",
    height: "fit-content"
};

export default ({
    workflowNodeData,
    workflowEdgesData,
    columnId,
}) => {

    const { checkTryWithoutLogin, openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentProject, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [form] = Form.useForm();
    const repoUrlRegex = /(?:https?:\/\/)?(?:www\.)?(?:github\.com|gitlab\.com)\/([^/\s]+)\/([^/\s]+)/i;

    const [sourceData, setSourceData] = useState({
        autoTrigger: "",
        appSource: "",
        repo: "",
        repoId: "",
        accountId: "",
        accountName: "",
        repoUrl: "",
        branchConfig: "",
        branchConfigValue: [],
        branch: "",
        buildType: "",
        sourceType: "",
        containerRegistry: {},
        containerRegistryId: "",
        imageRepo: "",
        imageTag: "",
        isPrivate: false,
        isOwner: false,
    });

    // const [dynamicname, setAccountsConnected] = useState();
    const [accountsConnected, setAccountsConnected] = useState();
    const [projectServiceData, setProjectServiceData] = useState();
    const [repoList, setRepoList] = useState();
    const [imageTagList, setImageTagList] = useState([]);
    const [branches, setBranches] = useState();
    const [automaticBranches, setAutomaticBranches] = useState();
    const [autoTriggerOptions, setAutoTriggerOptions] = useState();
    const [branchConfigDropdown, setBranchConfigDropdown] = useState();
    const [fetching, setFetching] = useState(true);
    const [fetchingBranches, setFetchingBranches] = useState(true);
    const [searchValue, setSearchValue] = useState("");

    const [imageRepoList, setImageRepoList] = useState([]);
    const [containerRegistryList, setContainerRegistryList] = useState([]);
    const [containerRegs, setContainerRegs] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [sourceFetching, setSourceFetching] = useState(true);
    const [openAppSourceDropdown, setOpenAppSourceDropdown] = useState(false);
    const [imageRepoLoading, setImageRepoLoading] = useState(true);
    const [imageTagLoading, setImageTagLoading] = useState(true);

    useEffect(() => {
        let sourceTypes = ["Git Repositories", "Container Registry"];
        let sourceOptions = [];
        sourceTypes.forEach((val) => {
            sourceOptions.push(
                <Option key={val} value={val} name="sourceType">{val}</Option>
            );
        });
        setSourceList(sourceOptions);
    }, []);

    useEffect(() => {
        console.log('location.pathname :', location.pathname);
        let tempServiceId = currentWorkflowData?.fromPipeline ? currentWorkflowData.serviceId?.toString() : location.pathname.split('/').slice(-3)[0];
        let tempService;

        console.log('tempServiceId in buildClickRightDrawer :', tempServiceId);
        console.log('currentProject :', currentProject);
        console.log('location.state :', location.state);
        console.log("currentWorkflowData in sourceClickRightDrawer :", currentWorkflowData);
        console.log("sourceData in sourceClickRightDrawer :", sourceData);

        currentProject.tasks.forEach((temp, idx) => {
            if (temp && temp.serviceId.toString() === tempServiceId) {
                tempService = currentProject.tasks[idx]
                setProjectServiceData(currentProject.tasks[idx]);
            }
        });

        console.log("tempService in sourceClickRightDrawer :", tempService);

        setSourceData({
            autoTrigger: currentWorkflowData?.sourceConfig?.config?.autoTrigger ? currentWorkflowData.sourceConfig.config.autoTrigger : false,
            appSource: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.source : ""),
            repo: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? currentWorkflowData.sourceConfig.config.docker_repo_name : (tempService ? tempService.repo : ""),
            repoId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? (tempService.repo_id ? tempService.repo_id : "") : ""),
            sourceType: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "Container Registry" : (tempService ? tempService.sourceType : ""),
            containerRegistry: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? 
                (currentWorkflowData?.sourceConfig?.config?.container_registry_id ? null : "PUBLIC")
                : (tempService ? tempService.container_registry_id ? tempService.containerRegistry : "PUBLIC" : {}),
            containerRegistryId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? currentWorkflowData.sourceConfig.config.container_registry_id : (tempService ? tempService.container_registry_id : ""),
            imageRepo: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? (currentWorkflowData.sourceConfig.config.repository_name ? currentWorkflowData.sourceConfig.config.repository_name : currentWorkflowData.sourceConfig.config.image_name) : (tempService ? tempService.imageRepo : ""),
            imageTag: currentWorkflowData?.sourceConfig?.config?.image_tag ? currentWorkflowData.sourceConfig.config.image_tag : "",
            integrationType: currentWorkflowData?.sourceConfig?.config?.integration_type ? currentWorkflowData.sourceConfig.config.integration_type : "",
            region: currentWorkflowData?.sourceConfig?.config?.region ? currentWorkflowData.sourceConfig.config.region : "",
            alias: currentWorkflowData?.sourceConfig?.config?.alias ? currentWorkflowData.sourceConfig.config.alias : (currentWorkflowData?.sourceConfig?.config?.docker_repo_name ? currentWorkflowData.sourceConfig.config.docker_repo_name : ""),
            accountId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.accountId : ""),
            accountName: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.accountName : ""),
            repoUrl: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.repoUrl : ""),
            branchConfig: currentWorkflowData?.sourceConfig?.config?.branchConfig ? currentWorkflowData.sourceConfig.config.branchConfig : "",
            branchConfigValue: currentWorkflowData?.sourceConfig?.config?.branchConfigValue ? currentWorkflowData.sourceConfig.config.branchConfigValue : "",
            branch: currentWorkflowData?.sourceConfig?.config?.branch ? currentWorkflowData.sourceConfig.config.branch : "",
            buildType: currentWorkflowData?.sourceConfig?.config?.buildType ? currentWorkflowData.sourceConfig.config.buildType : "Manual",
            isPrivate: false,
            isOwner: false,
        });

        form.setFieldsValue({
            autoTrigger: currentWorkflowData?.sourceConfig?.config?.autoTrigger ? currentWorkflowData.sourceConfig.config.autoTrigger : false,
            appSource: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.source : ""),
            repo: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? currentWorkflowData.sourceConfig.config.docker_repo_name : (tempService ? tempService.repo : ""),
            repoId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? (tempService.repo_id ? tempService.repo_id : "") : ""),
            sourceType: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "Container Registry" : (tempService ? tempService.sourceType : ""),
            containerRegistry: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? 
                (currentWorkflowData?.sourceConfig?.config?.container_registry_id ? null : "PUBLIC")
                : (tempService ? tempService.container_registry_id ? tempService.containerRegistry : "PUBLIC" : {}),
            containerRegistryId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? currentWorkflowData.sourceConfig.config.container_registry_id : (tempService ? tempService.container_registry_id : ""),
            imageRepo: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? (currentWorkflowData.sourceConfig.config.repository_name ? currentWorkflowData.sourceConfig.config.repository_name : currentWorkflowData.sourceConfig.config.image_name) : (tempService ? tempService.imageRepo : ""),
            imageTag: currentWorkflowData?.sourceConfig?.config?.image_tag ? currentWorkflowData.sourceConfig.config.image_tag : "",
            integrationType: currentWorkflowData?.sourceConfig?.config?.integration_type ? currentWorkflowData.sourceConfig.config.integration_type : "",
            region: currentWorkflowData?.sourceConfig?.config?.region ? currentWorkflowData.sourceConfig.config.region : "",
            alias: currentWorkflowData?.sourceConfig?.config?.alias ? currentWorkflowData.sourceConfig.config.alias : (currentWorkflowData?.sourceConfig?.config?.docker_repo_name ? currentWorkflowData.sourceConfig.config.docker_repo_name : ""),
            accountId: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.accountId : ""),
            accountName: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.accountName : ""),
            repoUrl: currentWorkflowData?.fromPipeline && currentWorkflowData?.withoutCI ? "" : (tempService ? tempService.repoUrl : ""),
            branchConfig: currentWorkflowData?.sourceConfig?.config?.branchConfig ? currentWorkflowData.sourceConfig.config.branchConfig : "",
            branchConfigValue: currentWorkflowData?.sourceConfig?.config?.branchConfigValue ? currentWorkflowData.sourceConfig.config.branchConfigValue : "",
            branch: currentWorkflowData?.sourceConfig?.config?.branch ? currentWorkflowData.sourceConfig.config.branch : "",
            buildType: currentWorkflowData?.sourceConfig?.config?.buildType ? currentWorkflowData.sourceConfig.config.buildType : "Manual",
            isPrivate: false,
            isOwner: false,
        });
    }, []);

    // useEffect(() => {
    //     if (sourceFetching) {
    //         api.post(process.env.REACT_APP_API_URI + "/api/integration/account/list/", {
    //             "category_id": 2
    //         })
    //             .then((res) => {
    //                 let data = res.data;
    //                 console.log("integration/list data :", data);
    //                 if (data.status) {
    //                     // let dataSource = [];
    //                     let appSource = [];
    //                     // Public on top of appSource
    //                     appSource.push(
    //                         <Option key={appSource.length - 1} name="appSource" data-alias="" type="public">
    //                             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
    //                                 <span>PUBLIC</span>
    //                             </div>
    //                         </Option>
    //                     );

    //                     data.data.map((temp, ind) => {
    //                         if (temp.status) {
    //                             appSource.push(
    //                                 <Option key={ind} name="appSource" data-key={temp.id} type={temp.integration_type} data-alias={temp.alias} value={`${temp.integration_type.toUpperCase()}${temp.alias ? ` (${temp.alias})` : ""}`}>
    //                                     <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
    //                                         <span>{`${temp.integration_type.toUpperCase()}${temp.alias ? ` (${temp.alias})` : ""}`}</span>
    //                                     </div>
    //                                 </Option>
    //                             );
    //                         }
    //                     });

    //                     // Integrate New Account on bottom of appSource
    //                     appSource.push(
    //                         <Option key={appSource.length} name="appSource" data-alias="" type="integration" style={{ color: "white", background: "#1677ff" }}>
    //                             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
    //                                 <span>Integrate New Account</span>
    //                             </div>
    //                         </Option>
    //                     );

    //                     console.log('appSource :', appSource);
    //                     setAccountsConnected(appSource);
    //                     setSourceFetching(false);
    //                 }
    //                 else {
    //                     let err = formatErrors(data.error_details);
    //                     console.log("Err :", err);
    //                     setSourceFetching(false);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log("err :", err);
    //                 setSourceFetching(false);
    //                 // toggleAlert("error", "Error", "No Data Found. Please try again.");
    //             });
    //     }
    // }, [sourceFetching]);

    useEffect(() => {
        let tempDropdown = [];
        let tempAutoTriggerDropdown = [];

        ["Branch", "Wildcard Branch"].forEach((child, ind) => {         // , "Tags", "Pull request"
            tempDropdown.push(
                <Option key={child} name="branchConfig" data-id="" data-region={null} value={child}>
                    <div style={{ width: "100%", display: "flex" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        ["Automatic", "Manual"].forEach((child, ind) => {         // , "Tags", "Pull request"
            tempAutoTriggerDropdown.push(
                <Option key={child} name="buildType" data-id="" data-region={null} value={child}>
                    <div style={{ width: "100%", display: "flex" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        setBranchConfigDropdown(tempDropdown);
        setAutoTriggerOptions(tempAutoTriggerDropdown);
    }, []);

    useEffect(() => {
        console.log("sourceData.sourceType :", sourceData.sourceType)
        if (sourceData.sourceType === "Container Registry") {
            let regOptions = [];
            api.get('/api/integration/container-registries/').then((res) => {
                let data = res.data;
                let arr = data.data;
                let tempContainerRegistry = {}
                arr.push("PUBLIC");

                if (data.status) {
                    arr.forEach((child, ind) => {
                        if (child !== "PUBLIC") {
                            regOptions.push(
                                <Option key={child.alias + ind + '!'} name={"containerRegistry"} data-id={child.id} data-region={child.region} value={!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`}>
                                    <div style={{ width: "100%", display: "flex" }}>
                                        <p> {!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`} </p>
                                    </div>
                                </Option>);
                        }
                        else {
                            regOptions.push(
                                <Option key={child} name={"containerRegistry"} data-id="" data-region={null} value={child}>
                                    <div style={{ width: "100%", display: "flex" }}>
                                        <p> {child} </p>
                                    </div>
                                </Option>);
                        }

                        // console.log("projectServiceData in container registry :", projectServiceData);
                        // console.log("child.id in container registry :", child.id);
                        // console.log("container_registry_id in container registry :", projectServiceData?.container_registry_id);
                        // check if adding new workflow or updating existing
                        if (currentWorkflowData?.addWorkflow && child.id === projectServiceData?.container_registry_id){
                            tempContainerRegistry = child
                        }
                        else if (currentWorkflowData?.sourceConfig?.config?.container_registry_id && child.id === currentWorkflowData.sourceConfig.config.container_registry_id) {
                            tempContainerRegistry = child
                        }
                    });
                    setContainerRegistryList(regOptions);
                    setContainerRegs(data.data);

                    console.log("setting tempContainerRegistry :", tempContainerRegistry);

                    // set container registry in source data
                    setSourceData({
                        ...sourceData,
                        containerRegistry: sourceData.containerRegistry === 'PUBLIC' ? "PUBLIC" : tempContainerRegistry,
                        alias: tempContainerRegistry.alias,
                        accountId: tempContainerRegistry.account_id,
                        integrationType: tempContainerRegistry.integration_type,
                        region: tempContainerRegistry.region,
                    });

                    form.setFieldsValue({
                        containerRegistry: sourceData.containerRegistry === 'PUBLIC' ? "PUBLIC" : tempContainerRegistry,
                        alias: tempContainerRegistry.alias,
                        accountId: tempContainerRegistry.account_id,
                        integrationType: tempContainerRegistry.integration_type,
                        region: tempContainerRegistry.region,
                    });
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Err :", err);
                }
            }).catch((err) => {
                console.log("Error :", err);
            })
        }
    }, [sourceData.sourceType]);

    useEffect(() => {
        const fetchImageRepos = () => {
            console.log("sourceData in fetchImageRepos :", sourceData);
            if (sourceData.containerRegistry && Object.keys(sourceData.containerRegistry).length > 0 && sourceData.containerRegistry.id) {
                let imageRepos = [];
                api.get(`/api/integration/repositories/${sourceData.containerRegistry.id}/`).then((res) => {
                    let data = res.data;
                    if (data.status) {
                        data.data.forEach((val) => {
                            imageRepos.push(
                                <Option key={val} value={val} name="imageRepo">{val}</Option>
                            );
                        });
                        setImageRepoList(imageRepos);
                        setImageRepoLoading(false);
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("Err :", err);
                        setImageRepoLoading(false);
                    }
                }).catch((err) => {
                    console.log("Error");
                    setImageRepoLoading(false);
                });
            }
        }

        let myRepoTimeout = setTimeout(fetchImageRepos, 2000);

        return (() => clearTimeout(myRepoTimeout))
    }, [sourceData.containerRegistry]);

    useEffect(() => {
        if (sourceData.containerRegistryId && sourceData.imageRepo) {
            api.get(`/api/integration/images/${sourceData.containerRegistryId}/${sourceData.imageRepo}`).then((res) => {
                let data = res.data;
                console.log("data in /api/integration/images/ :", data);
                if (data.status) {
                    let imageTags = [];
                    data.data.forEach((val) => {
                        imageTags.push(
                            <Option key={val} value={val} name="imageTag">{val}</Option>
                        );
                    });
                    setImageTagList(imageTags);
                    setImageTagLoading(false);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Err :", err);
                    setImageTagLoading(false);
                }
            }).catch((err) => {
                console.log("Error :", err);
                setImageTagLoading(false);
            });
        }
    }, [sourceData.imageRepo]);


    const handleAppSourceRefresh = () => {
        console.log("handleAppSourceRefresh :", sourceFetching);
        setSourceFetching(true);
        // setOpenAppSourceDropdown(false);
    }

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    const getRepos = (accountId) => {
        console.log('Going to get repos');
        let payload = {
            "account_id": accountId
        }

        api.post(process.env.REACT_APP_API_URI + "/api/integration/vcs/repos/", payload)
            .then((res) => {
                let data = res.data;
                console.log("repo/list data :", data);
                if (data.status) {
                    let repos = [];

                    data.data.map((temp, ind) => {
                        repos.push(
                            <Option key={ind} name="repo" type={temp.name} data-isprivate={temp.private} data-url={temp.repo_url} is-owner={temp.owner}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p>{temp.name}</p>
                                        <p style={{
                                            fontFamily: "Nunito Sans",
                                            fontSize: "10px",
                                            color: "lightgray"
                                        }}>Atmosly requires you to have repo ownership otherwise some functionalities may not work.</p>
                                    </div>
                                    <span>{temp.private ? "Private" : "Public"}</span>
                                </div>
                            </Option>
                        );
                    });

                    console.log('repos :', repos);
                    setRepoList(repos);
                    setFetching(false);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    setFetching(false);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                setFetching(false);
                if (err === "Try without login") {
                }
                else {
                    //   toggleAlert("error", "Error", "Something went wrong. Please try again.");
                }
            });
    }

    useEffect(() => {
        if (sourceData.appSource && (sourceData.accountId || sourceData.accountName)) {
            setFetching(true);
            getRepos(sourceData.accountId);
        }
        else if (sourceData.appSource && sourceData.appSource === "public") {
            setRepoList();
            // setBranchList();
        }
    }, [sourceData.appSource, sourceData.accountId, sourceData.accountName]);

    // useEffect(() => {
    //     if (sourceData.branchConfig) {
    //         setSourceData({...sourceData, branchConfigValue: ""});
    //         form.setFieldsValue({ ...sourceData, branchConfigValue: "" });
    //     }
    // }, [sourceData.branchConfig]);

    useEffect(() => {
        async function fetchData() {
            console.log("sourceData while branches :", sourceData);
            if (sourceData.repo || sourceData.repoUrl) {
                setFetchingBranches(true);
                if (sourceData.accountId === null || sourceData.accountId === "") {
                    let rawData = sourceData.repoUrl.split("/");
                    let owner = rawData[3];
                    let repoName = rawData[4];
                    if (repoName) {
                        repoName = repoName.split(".git")[0];
                    }
                    if (sourceData.repoUrl.includes("github")) {
                        fetch(`https://api.github.com/repos/${owner}/${repoName}/branches`)
                            .then(async (res) => await res.json())
                            .then((data) => {
                                console.log('branches data :', data);
                                if (data.message) {
                                    console.log("Please provide correct url");
                                }
                                else {
                                    let tempBranches = [];
                                    let tempAutomaticBranches = [];

                                    data.map((temp, ind) => {
                                        tempBranches.push(
                                            <Option key={ind} name="branch" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );

                                        tempAutomaticBranches.push(
                                            <Option key={ind} name="branchConfigValue" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );
                                    });
                                    setBranches(tempBranches);
                                    setAutomaticBranches(tempAutomaticBranches);
                                }
                            })
                            .catch((error) => {
                                console.error('Error fetching branches:', error);
                            });
                    }
                    else {
                        fetch(`https://gitlab.com/api/v4/projects/${owner}%2F${repoName}/repository/branches`)
                            .then(async (res) => {
                                let data = await res.json();
                                if (data.message) {
                                    console.log("Please provide correct url");
                                }
                                else {
                                    let tempBranches = [];
                                    let tempAutomaticBranches = [];

                                    data.map((temp, ind) => {
                                        tempBranches.push(
                                            <Option key={ind} name="branch" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );

                                        tempAutomaticBranches.push(
                                            <Option key={ind} name="branchConfigValue" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );
                                    });
                                    setBranches(tempBranches);
                                    setAutomaticBranches(tempAutomaticBranches);
                                }
                            }).catch((error) => {
                                console.error('Error fetching branches:', error);
                            });
                    }
                }
                else {
                    let branchesData = await fetchBranches(sourceData.accountId, sourceData.repo);
                    console.log("branchesData :", branchesData);
                    if (branchesData.status) {
                        let tempBranches = [];
                        let tempAutomaticBranches = [];

                        branchesData.data.map((temp, ind) => {
                            tempBranches.push(
                                <Option key={ind} name="branch" value={temp}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                        <span>{temp}</span>
                                    </div>
                                </Option>
                            );

                            tempAutomaticBranches.push(
                                <Option key={ind} name="branchConfigValue" value={temp}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                        <span>{temp}</span>
                                    </div>
                                </Option>
                            );
                        });
                        setBranches(tempBranches);
                        setAutomaticBranches(tempAutomaticBranches);
                    }
                }
                setFetchingBranches(false);
            }
        }
        fetchData();
    }, [sourceData.repo, sourceData.repoUrl]);

    const handleSelectChanges = (value, event) => {
        // integration-redirect
        console.log("inside handleSelectChanges event :", event);
        console.log("inside handleSelectChanges value :", value);
        if (event.name === "appSource" && event["data-key"]) {
            setSourceData({ ...sourceData, [event.name]: event.type, repo: "", repoUrl: "", accountId: event["data-key"], accountName: event["data-alias"], isPrivate: false });
            form.setFieldsValue({ ...sourceData, [event.name]: event.type, repo: "", repoUrl: "", accountId: event["data-key"], accountName: event["data-alias"], isPrivate: false });
        }
        else if ((event.name === "appSource") && (event.type === "public")) {
            setSourceData({ ...sourceData, [event.name]: event.type, repo: "", repoUrl: "", accountId: "", accountName: "", branch: "", isPrivate: false });
            form.setFieldsValue({ ...sourceData, [event.name]: event.type, repo: "", repoUrl: "", accountId: "", accountName: "", branch: "", isPrivate: false });
            return;
        }
        else if (event.name === "repo") {
            console.log('event["data-url"] :', event["data-url"]);
            console.log('event["data-isprivate"] :', event["data-isprivate"]);
            setSourceData({ ...sourceData, [event.name]: event.type, repoUrl: event["data-url"], branch: "", isPrivate: event["data-isprivate"], isOwner: event["is-owner"] });
            form.setFieldsValue({ ...sourceData, [event.name]: event.type, repoUrl: event["data-url"], branch: "", isPrivate: event["data-isprivate"], isOwner: event["is-owner"] });
        }
        else if (event.type === "integration") {
            handleCancel();
            window.open(`${process.env.REACT_APP_GOOGLE_REDIRECT_URI}/integrations`, "_blank");
            return;
        }
        else if (event.name === "sourceType" || event.name === "imageRepo") {
            if (event.name === "sourceType") {
                sourceData.appSource = "";
                sourceData.containerRegistry = {};
                sourceData.imageRepo = "";
                sourceData.repoUrl = "";
            }
            console.log("Enters sourceType: ", event.name, value);
            setSourceData({ ...sourceData, [event.name]: value });
        }
        else if (event.name === "branch") {
            setSourceData({ ...sourceData, [event.name]: event.value, branchConfigValue: "", branchConfig: "" });
            form.setFieldsValue({ ...sourceData, [event.name]: event.value, branchConfigValue: "", branchConfig: "" });
        }
        else if (event.name === "branchConfig") {
            setSourceData({ ...sourceData, [event.name]: event.value, branchConfigValue: event.value === "Branch" ? [] : "" });
            form.setFieldsValue({ ...sourceData, [event.name]: event.value, branchConfigValue: event.value === "Branch" ? [] : "" });
        }
        else {
            setSourceData({ ...sourceData, [event.name]: event.value });
            form.setFieldsValue({ ...sourceData, [event.name]: event.value });
        }
    }

    const handleMultiSelectChange = (value, event) => {
        // integration-redirect
        console.log("inside handleMultiSelectChange event :", event);
        console.log("inside handleMultiSelectChange value :", value);


        setSourceData({ ...sourceData, 'branchConfigValue': value });
        form.setFieldsValue({ ...sourceData, 'branchConfigValue': value });
    }

    const handleChecked = (value) => {
        console.log("inside handleChecked value :", value);
        setSourceData({ ...sourceData, autoTrigger: value });
    }

    const handleSaveDetails = async (e) => {
        console.log("currentWorkflowData in handleSaveDetails :", currentWorkflowData);
        console.log("sourceData in handleSaveDetails :", sourceData);
        try {
            const values = await form.validateFields();
            console.log('Success:', values);

            currentWorkflowData.sourceConfig.config = {
                ...currentWorkflowData.sourceConfig.config,
                auto_trigger: sourceData.autoTrigger,
                git_integration_type: sourceData.appSource,
                repo_name: sourceData.repo,
                repo_id: sourceData.repoId,
                git_account_id: sourceData.accountId,
                alias: sourceData.accountName,
                repo_url: sourceData.repoUrl,
                branch: sourceData.branch,
                branchConfig: sourceData.branchConfig,
                branchConfigValue: sourceData.branchConfigValue,
                buildType: sourceData.buildType,
                app_source_type: sourceData.sourceType === "Git Repositories" ? 1 : 0,
                container_registry_id: sourceData.containerRegistryId,
                repository_name: sourceData.imageRepo,
                image_tag: sourceData.imageTag,
                isPrivate: sourceData.branch,
                isOwner: sourceData.branch,
            }

            setCurrentWorkflowData({ ...currentWorkflowData });
            localStorage.setItem("Workflow", JSON.stringify({ ...currentWorkflowData }));
            handelCancel();
        }
        catch (err) {
            console.log("Found error :", err);
        }

    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelCancel();
    }

    const handelInputChange = (e) => {
        console.log("clicked handelInputChange :", e.target);
        setSourceData({ ...sourceData, [e.target.name]: e.target.value });
        form.setFieldsValue({ ...sourceData, [e.target.name]: e.target.value });
    }

    const handleSource = (event, value) => {
        console.log("handleSource event.target :", event.target);
        console.log("handleSource event.target.tagName :", event.target.tagName);
        console.log("handleSource :", value);
        // if(event.target?.tagName !== "INPUT"){
        if (!value) {
            setTimeout(() => {
                setOpenAppSourceDropdown(value);
            }, 300);
            // setOpenSource(false);
        }
        else {
            setOpenAppSourceDropdown(!openAppSourceDropdown);
            // setOpenSource(!openSource);
        }
        // }
    }

    const filterReposOption = (input, option) => {
        if (option.key) {
            return (option?.props.type.toLowerCase() ?? '').includes(input.toLowerCase())
        }
    };

    const filterOption = (input, option) => {
        if (option.key) {
            return (option?.props.value.toLowerCase() ?? '').includes(input?.toLowerCase())
        }
    };

    // if (currentWorkflowData?.fromPipeline) {
    //     return (
    //         <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
    //             <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />
    //             <div style={{ marginTop: "84px", width: "100%" }} />
    //             <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
    //                 {sourceData.sourceType === "Git Repositories" && <div>
    //                     <Box
    //                         title={"Repo URL"}
    //                         subtitle={sourceData.repoUrl}
    //                         style={{ ...boxStyle, position: "relative" }}
    //                         titleStyle={boxTitleStyle}
    //                         subtitleStyle={boxSubtitleStyle}
    //                     />
    //                     <Box
    //                         title={"Build Type"}
    //                         subtitle={sourceData.buildType}
    //                         style={{ ...boxStyle, position: "relative" }}
    //                         titleStyle={boxTitleStyle}
    //                         subtitleStyle={boxSubtitleStyle}
    //                     />
    //                     <Box
    //                         title={"Branch"}
    //                         subtitle={sourceData.branch}
    //                         style={{ ...boxStyle, position: "relative" }}
    //                         titleStyle={boxTitleStyle}
    //                         subtitleStyle={boxSubtitleStyle}
    //                     />
    //                 </div>}
    //             </StickerWidgetWrapper>
    //         </LayoutWrapper>
    //     );
    // }
    // else {
        return (
            <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
                <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />
                {console.log("sourceData in HTML :", JSON.parse(JSON.stringify(sourceData)))}
                <div style={{ marginTop: "84px", width: "100%" }} />

                <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                    <Form
                        form={form}
                        initialValues={{ ...sourceData }}
                        preserve={false}
                        name="dynamic_rule"
                        style={{
                            width: "100%",
                        }}
                    >

                        {/* {sourceData.sourceType === "Git Repositories" && <Box
                        title={"Enable CI/CD"}
                        subtitle={"Check the box to enable CI/CD."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                        <Switch style={sourceData.autoTrigger ? { backgroundColor: "#132f44" } : {}} checked={sourceData?.autoTrigger} onChange={handleChecked} />
                    </Box>} */}

                        <Box
                            title={`Application Source Type${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={"Select app source type here"}
                            style={{ ...boxStyle, position: "relative" }}
                            titleStyle={boxTitleStyle}
                            subtitleStyle={boxSubtitleStyle}
                        >
                            <Select
                                className="customSelectDataSource"
                                style={{ width: '100%' }}
                                value={sourceData.sourceType}
                                onChange={handleSelectChanges}
                                disabled={true}
                            >
                                {sourceList}
                            </Select>
                        </Box>

                        {sourceData.sourceType === "Git Repositories" && <Box
                            title={`Git Source${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={"Select git source here"}
                            style={{ ...boxStyle, position: "relative" }}
                            titleStyle={boxTitleStyle}
                            subtitleStyle={boxSubtitleStyle}
                            imgStyle={imgStyle}
                        >
                            {console.log("sourceData in html :", sourceData)}
                            <Select
                                className="customSelectDataSource"
                                style={{ width: '100%' }}
                                value={sourceData.appSource ?
                                    `${sourceData.appSource.toUpperCase()}${sourceData.accountName ? ` (${sourceData.accountName})` : ""}`
                                    :
                                    undefined
                                }
                                // open={openSource}
                                onChange={handleSelectChanges}
                                onClick={(e) => handleSource(e, true)}
                                onBlur={(e) => handleSource(e, false)}
                                disabled={true}
                            >
                                {sourceFetching ?
                                    <Option>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                            <Spin size="small" />
                                        </div>
                                    </Option>
                                    :
                                    accountsConnected
                                }
                            </Select>
                            {openAppSourceDropdown && <img src={refreshIcon} style={{ position: "absolute", top: "10px", right: "0", cursor: "pointer" }} onClick={handleAppSourceRefresh} />}
                        </Box>}

                        {sourceData.sourceType === "Container Registry" &&
                            <>
                                <Box
                                    title={`Container Registry${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                    subtitle={"Provide container registry here"}
                                    style={{ ...boxStyle, position: "relative" }}
                                    titleStyle={boxTitleStyle}
                                    subtitleStyle={boxSubtitleStyle}
                                >
                                    <Select
                                        className="customSelectDataSource"
                                        style={{ width: '100%' }}
                                        // defaultValue={sourceData.containerRegistry === "PUBLIC" ? sourceData.containerRegistry : (!sourceData.accountId ? `${sourceData.alias} - ${sourceData.integrationType}` : `${sourceData.accountId}${sourceData.alias ? `(${sourceData.alias})` : ``} / ${sourceData.region}`)}
                                        value={sourceData.containerRegistry === "PUBLIC" ? sourceData.containerRegistry : (!sourceData.accountId ? `${sourceData.alias} - ${sourceData.integrationType}` : `${sourceData.accountId}${sourceData.alias ? `(${sourceData.alias})` : ``} / ${sourceData.region}`)}
                                        // value={sourceData.containerRegistry === "PUBLIC" ? sourceData.containerRegistry : ((!sourceData.containerRegistry || Object.keys(sourceData.containerRegistry).length === 0) ? null : !sourceData.containerRegistry.account_id ? `${sourceData.containerRegistry.alias} - ${sourceData.containerRegistry.integration_type}` : `${sourceData.containerRegistry.account_id}${sourceData.containerRegistry.alias ? `(${sourceData.containerRegistry.alias})` : ``} / ${sourceData.containerRegistry.region}`)}
                                        // open={openSource}
                                        disabled={true}
                                        onChange={(value, event) => {
                                            console.log('value :', value);
                                            containerRegs.map((val) => {
                                                console.log('val&value :', val.alias, value);
                                                if (val.id && event["data-id"] === val.id) {
                                                    // console.log('val :', val);
                                                    setSourceData(sourceData => ({
                                                        ...sourceData,
                                                        containerRegistry: val,
                                                        imageRepo: ""
                                                    }));
                                                    setImageRepoLoading(true);
                                                }
                                            })
                                        }}                                // onClick={(e) => handleSource(e, true)}
                                    // onBlur={(e) => handleSource(e, false)}
                                    >
                                        {/* {containerRegistryList} */}
                                    </Select>
                                </Box>

                                {(currentWorkflowData.withoutCI) && <Box
                                    title={`Image Repo${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                    subtitle={"Provide repo here"}
                                    style={{ ...boxStyle, position: "relative" }}
                                    titleStyle={boxTitleStyle}
                                    subtitleStyle={boxSubtitleStyle}
                                >
                                    {sourceData.containerRegistry !== "PUBLIC" ?
                                        <Select
                                            className="customSelectDataSource"
                                            style={{ width: '100%' }}
                                            value={sourceData.imageRepo}
                                            disabled={true}
                                            showSearch
                                            filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                            filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                            onSearch={(value) => {
                                                console.log("Enters search", value);
                                                setSearchValue(value);
                                            }}
                                            // open={openSource}
                                            onChange={handleSelectChanges}
                                            notFoundContent={imageRepoLoading && <Spin spinning={imageRepoLoading} />}
                                        // onClick={(e) => handleSource(e, true)}
                                        // onBlur={(e) => handleSource(e, false)}
                                        >
                                            {imageRepoList}
                                        </Select>
                                        :
                                        <Input name="imageRepo" style={{
                                            ...inputStyle,
                                            color: "rgba(0, 0, 0, 0.25)",
                                            background: "rgba(0, 0, 0, 0.04)",
                                            cursor: "not-allowed",
                                        }} value={sourceData.imageRepo} onChange={handelInputChange} disabled={true} />
                                    }

                                </Box>}

                                {!currentWorkflowData?.withoutCI && <Box
                                    title={`Build Type${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                    subtitle={"Select build type from the following."}
                                    titleStyle={boxTitleStyle}
                                    subTitleStyle={boxSubtitleStyle}
                                    style={{ ...boxStyle }}
                                >
                                    <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                        <Form.Item
                                            name="buildType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please provide build type.',
                                                },
                                            ]}
                                        >
                                            <Select
                                                name="buildType"
                                                value={sourceData?.buildType ? sourceData.buildType : undefined}
                                                placeholder="Select"
                                                onChange={handleSelectChanges}
                                                style={{ width: "100%" }}
                                                className="customSelectDataSource"
                                                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                                onSearch={(value) => {
                                                    console.log("Enters search", value);
                                                    setSearchValue(value);
                                                }}
                                                disabled={currentWorkflowData?.fromPipeline}
                                                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                            //filterOption={filterOption}
                                            // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                            >
                                                {autoTriggerOptions}
                                            </Select>
                                        </Form.Item>
                                    </ContentHolder>
                                </Box>}

                                {sourceData.imageRepo && <Box
                                    title={`Image Tag${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                    subtitle={"Provide tag here"}
                                    style={{ ...boxStyle, position: "relative", marginBottom: "150px" }}
                                    titleStyle={boxTitleStyle}
                                    subtitleStyle={boxSubtitleStyle}
                                >
                                    <Form.Item
                                        name="imageTag"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide image tag.',
                                            },
                                        ]}
                                    >
                                        {sourceData.containerRegistry !== "PUBLIC" ?
                                            <Select
                                                className="customSelectDataSource"
                                                style={{ width: '100%' }}
                                                value={sourceData.imageTag}
                                                showSearch
                                                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                                onSearch={(value) => {
                                                    console.log("Enters search", value);
                                                    setSearchValue(value);
                                                }}
                                                disabled={currentWorkflowData?.fromPipeline}
                                                // open={openSource}
                                                onChange={handleSelectChanges}
                                                notFoundContent={imageTagLoading && <Spin spinning={imageTagLoading} />}
                                            // onClick={(e) => handleSource(e, true)}
                                            // onBlur={(e) => handleSource(e, false)}
                                            >
                                                {imageTagList}
                                            </Select>

                                            :

                                            <Input name="imageTag" style={inputStyle} disabled={currentWorkflowData?.fromPipeline} value={sourceData.imageTag} onChange={handelInputChange} />
                                        }
                                    </Form.Item>
                                </Box>}
                            </>}


                        {/* ----------old---------- */}
                        {/* <Box
                        title={"App Source*"}
                        subtitle={"Select your account."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={boxStyle}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Select
                                    // value={sourceData?.appSource}
                                    value={sourceData.appSource ? 
                                        `${sourceData.appSource.toUpperCase()}${sourceData.accountName ? ` (${sourceData.accountName})` : ""}`
                                        :
                                        undefined
                                    }
                                    placeholder="Select"
                                    className="customSelectDataSource"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    disabled={true}
                                >
                                    {accountsConnected}
                                </Select>
                        </ContentHolder>
                    </Box> */}

                        {sourceData?.appSource && <>
                            {sourceData.appSource != "public" && <Box
                                title={`Repository${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                subtitle={"Select your repository."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={boxStyle}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="repo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide Repo.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={sourceData?.repo}
                                            placeholder="Select"
                                            onChange={handleSelectChanges}
                                            style={{ width: "100%" }}
                                            className="centricText"
                                            showSearch
                                            filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                            filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                            onSearch={(value) => {
                                                console.log("Enters search", value);
                                                setSearchValue(value);
                                            }}
                                            //filterOption={filterReposOption}
                                            disabled={true}
                                        // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        >
                                            {fetching ?
                                                <Option>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                        <Spin size="small" />
                                                    </div>
                                                </Option>
                                                :
                                                repoList
                                            }
                                        </Select>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>}

                            {/* For Public */}
                            {sourceData.appSource === "public" && <Box
                                title={`Public Repo Url${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                                subtitle={"Repo Url Details"}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={boxStyle}
                                disabled={true}
                        >
                                <Form.Item
                                    name="repoUrl"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide Repo Url.',
                                        },
                                        {
                                            // Custom validator for only integers
                                            validator: (_, value) => {
                                                if (value && value.length > 1 && !repoUrlRegex.test(value)) {
                                                    return Promise.reject(new Error(`Please provide correct Repo Url`));
                                                }
                                                else {
                                                return Promise.resolve();
                                            }
                                        }
                                        },
                                    ]}
                                >
                                    <Input
                                        name="repoUrl"
                                        value={sourceData.repoUrl}
                                        onChange={handelInputChange}
                                        placeholder="Repo Url"
                                        style={{
                                        ...inputStyle,
                                        color: "rgba(0, 0, 0, 0.25)",
                                        background: "rgba(0, 0, 0, 0.04)",
                                        cursor: "not-allowed",
                                    }}
                                        disabled={true}
                                    // onBlur={getPublicBranches}
                                    />
                                </Form.Item>
                            </Box>}
                        </>}

                        {sourceData.repoUrl && <Box
                            title={`Build Type${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={"Select build type from the following."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ ...boxStyle }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Form.Item
                                    name="buildType"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide build type.',
                                        },
                                    ]}
                                >
                                    <Select
                                        name="buildType"
                                        value={sourceData?.buildType ? sourceData.buildType : undefined}
                                        placeholder="Select"
                                        onChange={handleSelectChanges}
                                        style={{ width: "100%" }}
                                        className="customSelectDataSource"
                                        filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                        filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                        onSearch={(value) => {
                                            console.log("Enters search", value);
                                            setSearchValue(value);
                                        }}
                                        disabled={currentWorkflowData?.fromPipeline}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    //filterOption={filterOption}
                                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        {autoTriggerOptions}
                                    </Select>
                                </Form.Item>
                            </ContentHolder>
                        </Box>}

                        {sourceData.repoUrl && sourceData.buildType === "Automatic" && <Box
                            title={`Branch Configure${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={"Select branch configure from the following."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ ...boxStyle }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px", marginBottom: sourceData.branchConfig ? "auto" : "100px" }}>
                                <Form.Item
                                    name="branchConfig"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide Branch Configure.',
                                        },
                                    ]}
                                >
                                    <Select
                                        value={sourceData?.branchConfig}
                                        placeholder="Select"
                                        onChange={handleSelectChanges}
                                        style={{ width: "100%" }}
                                        className="customSelectDataSource"
                                        showSearch
                                        filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                        filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                        onSearch={(value) => {
                                            console.log("Enters search", value);
                                            setSearchValue(value);
                                        }}
                                        disabled={currentWorkflowData?.fromPipeline}
                                    //filterOption={filterOption}
                                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        {branchConfigDropdown}
                                    </Select>
                                </Form.Item>
                            </ContentHolder>
                        </Box>}

                        {sourceData.repoUrl && sourceData.buildType === "Automatic" && sourceData.branchConfig && <Box
                            title={sourceData.branchConfig + `${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={`Select your ${sourceData.branchConfig}.`}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ ...boxStyle, marginBottom: "150px" }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                {sourceData.branchConfig === "Branch" &&
                                    <Form.Item
                                        name={currentWorkflowData?.fromPipeline ? "branch" : "branchConfigValue"}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please provide ${sourceData.branchConfig}.`,
                                            },
                                        ]}
                                    >
                                        <Select
                                            id="naz"
                                            mode="multiple"
                                            name="branchConfigValue"
                                            value={currentWorkflowData?.fromPipeline ? sourceData.branch : (sourceData?.branchConfigValue ? sourceData.branchConfigValue : [])}
                                            placeholder="Select"
                                            // onChange={handleSelectChanges}
                                            onChange={handleMultiSelectChange}
                                            // style={{ width: "100%" }}
                                            // className="customSelectDataSource"
                                            showSearch
                                            filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                            filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                            onSearch={(value) => {
                                                console.log("Enters search", value);
                                                setSearchValue(value);
                                            }}
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                            disabled={currentWorkflowData?.fromPipeline}
                                        >
                                            {fetchingBranches ?
                                                <Option>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                        <Spin size="small" />
                                                    </div>
                                                </Option>
                                                :
                                                automaticBranches
                                            }
                                        </Select>
                                    </Form.Item>
                                }

                                {sourceData.branchConfig === "Wildcard Branch" &&
                                    <Form.Item
                                        name={currentWorkflowData?.fromPipeline ? "branch" : "branchConfigValue"}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please provide ${sourceData.branchConfig}.`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            name={"branchConfigValue"}
                                            value={currentWorkflowData?.fromPipeline ? sourceData.branch : sourceData.branchConfigValue}
                                            onChange={handelInputChange}
                                            placeholder="Enter Value"
                                            style={{ ...inputStyle, cursor: currentWorkflowData?.fromPipeline ? "not-allowed" : "auto", opacity: currentWorkflowData?.fromPipeline ? "0.5" : "1", background: currentWorkflowData?.fromPipeline ? "rgba(0, 0, 0, 0.04)" : ""}}
                                            disabled={currentWorkflowData?.fromPipeline}
                                            // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                }

                                {/* {sourceData.branchConfig === "Tags" && <Select
                                    value={sourceData?.branchConfigValue ? sourceData.branchConfigValue : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    className="customSelectDataSource"
                                    showSearch
                                    filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                    filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                    onSearch={(value) => {
                                        console.log("Enters search", value);
                                        setSearchValue(value);
                                    }}
                                    //filterOption={filterOption}
                                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    {fetchingBranches ?
                                        <Option>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                <Spin size="small" />
                                            </div>
                                        </Option>
                                        :
                                        branches
                                    }
                                </Select>} */}

                                {/* {sourceData.branchConfig === "Pull request" && <Select
                                    value={sourceData?.branchConfigValue ? sourceData.branchConfigValue : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    className="customSelectDataSource"
                                    showSearch
                                    filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                    filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                    onSearch={(value) => {
                                        console.log("Enters search", value);
                                        setSearchValue(value);
                                    }}
                                    //filterOption={filterOption}
                                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    {fetchingBranches ?
                                        <Option>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                <Spin size="small" />
                                            </div>
                                        </Option>
                                        :
                                        branches
                                    }
                                </Select>} */}
                                
                                {/* {sourceData.branchConfig === "Wildcard Branch" &&
                                    <Form.Item
                                        name="branchConfigValue"
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please provide ${sourceData.branchConfig}.`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="branchConfigValue"
                                            value={sourceData.branchConfigValue}
                                            onChange={handelInputChange}
                                            placeholder="Enter Value"
                                            style={inputStyle}
                                            // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                } */}
                        </ContentHolder>
                    </Box>}

                        {sourceData.repoUrl && sourceData.buildType === "Manual" && <Box
                            title={`Branch${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                            subtitle={"Select branch from the following."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ ...boxStyle, marginBottom: "150px" }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Form.Item
                                    name="branch"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide branch.',
                                        },
                                    ]}
                                >
                                    <Select
                                        name="branch"
                                        value={sourceData?.branch ? sourceData.branch : undefined}
                                        placeholder="Select"
                                        onChange={handleSelectChanges}
                                        style={{ width: "100%" }}
                                        className="customSelectDataSource"
                                        filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                        filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                        showSearch
                                        onSearch={(value) => {
                                            console.log("Enters search", value);
                                            setSearchValue(value);
                                        }}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        disabled={currentWorkflowData?.fromPipeline}
                                //filterOption={filterOption}
                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        {checkTryWithoutLogin ? <Option key="0" style={{ background: "white" }} disabled>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                            <img src={noDataImg} alt="No data" />
                                            <span>No other branch</span>
                                        </div>
                                    </Option> : fetchingBranches ?
                                            <Option>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                    <Spin size="small" />
                                                </div>
                                            </Option>
                                            :
                                            branches
                                        }
                                    </Select>
                                </Form.Item>
                            </ContentHolder>
                        </Box>}
                    </Form>
                </StickerWidgetWrapper>

                {!currentWorkflowData?.fromPipeline && <CreateTaskFooter buttonContent={"Source Config"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} />}
            </LayoutWrapper>
        );
    }
// };